import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import certificate from "../assets/images/bbbee.png"
import { Link } from 'gatsby'

const PrivacyPolicy = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="BBBEE Certificate" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="BBBEE Compliance" 
            />
            <section className="privacy-policy-area ptb-100">
                <div className="container">
                    <div className="row">
                        <img src={certificate} alt="logo" width="28" height="3"/>
                    </div>
                </div>
            </section>
            <Footer />
        </Layout>
    )
}

export default PrivacyPolicy;